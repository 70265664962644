.loader {
  width: 50px;
  height: 50px;
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid dodgerblue;
  animation: spin 1s linear infinite;
  margin: 0 auto;
  top:50%;
  left:50%;
  margin-left: -25px;
  position: fixed;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100%  { transform: rotate(360deg); }
}
